import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"


const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`


const Footer = styled.footer`
  background: #0B41CD;
  display: flex;
  height: 120px;
  align-items: center;
`

const BlackTitleColor = styled.h1`
  color: #000000;
`

const PageTitleColor = styled.h1`
  color: #78c800;
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const folr1 = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#0B41CD",
        dividerColor: "#000000;",
        invert: {
          backgroundColor: "#0B41CD",
          dividerColor: "#FFFFFF;",
          primaryColor: "#FFFFFF",
        },
      }}
    >
      <>
        <Seo title="Where's FOLR1" />
        <SectionContainer>
          <Container>
            <AccentHeader size="large">
              <WorkLink to="/">
                  The Work
              </WorkLink>
            </AccentHeader>
              <div style={{ paddingBottom: "30px" }}>
                <PageTitle>
                  Where's FOLR1
                </PageTitle>
              </div>
            <Row>
              <Col md={4}>
                <BlackTitleColor>
                  <AccentHeader>The Problem</AccentHeader>
                </BlackTitleColor>
                <SectionBody size="regular">
                  Ovarian cancer doesn’t have the visibility or robust community of some of the more common cancers, yet there is a significant unmet need in treating its progression. Almost all patients will relapse and become resistant to the gold-standard chemo treatment.
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="extraLarge" weight="bold">
                  With options for these patients being limited, innovation in the ovarian cancer space was long overdue.
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>
        <RevomeMarginVideo>
          <VideoPlayer
            poster={() => (
              <Img fluid={data.videoThumbnail.childImageSharp.fluid} />
            )}
            url="https://player.vimeo.com/video/790557103?h=10893e694b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          />
        </RevomeMarginVideo>
        <SectionContainer invert noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px" }}>
            <Row>
              <Col md={4}>
                <AccentHeader>The Idea</AccentHeader>
                <SectionBody size="regular">
                  About 40% of patients who have failed chemotherapy for ovarian cancer have high expression for the FOLR1 biomarker.
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="extraLarge" weight="bold">
                  Roche’s first to market CDx for FOLR1 makes it easier to find these patients who could benefit from targeted 2nd line therapy.
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer>
          <Container>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </Container>
        </SectionContainer>

        <SectionContainer>
          <Container>
            <Row>
              <Col md={8}>
                <BlackTitleColor>
                  <AccentHeader>The Execution</AccentHeader>
                </BlackTitleColor>
                <SectionBody size="regular">
                  Little promise has existed in the ovarian cancer space, an ever-evolving disease with limited treatment options. Pathologists and Lab Directors needed to be aware of the new possibilities our first-to-market CDx had to offer. And through the use of illustration and color, we were able to simply telegraph just how many women had opportunity for a new path forward.
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer>
          <Container style={{ width: "100%" }}>
            <Img fluid={data.image2.childImageSharp.fluid} />
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ width: "100%" }}>
           <RevomeMarginImage>
              <Img fluid={data.image3.childImageSharp.fluid} />
           </RevomeMarginImage>
          </Container>
        </SectionContainer>
        
        <Footer></Footer>
      </>
    </ThemeProvider>
  </Layout>
)

export default folr1

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2023/folr1/image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image2: file(relativePath: { eq: "2023/folr1/image2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "2023/folr1/image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    videoThumbnail: file(relativePath: { eq: "2023/folr1/banner1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
